<template>
  <div>
    <div class="d-flex justify-center white--text display-1 mt-4">
      My Zudo Lists
    </div>
    <div>
      <TodoListsForm
        @add="handleAdd"
        @added="handleClearForm"
        v-model="newList"
      />
    </div>
    <TodoLists :items="todoLists" @edit="handleEdit" @delete="handleDelete" />
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import TodoLists from "@/components/TodoLists.vue";
import TodoListsForm from "@/components/TodoListsForm.vue";
const emptyTodoListObject = {
  id: undefined,
  title: undefined,
};

export default {
  name: "Home",
  components: {
    TodoLists,
    TodoListsForm,
  },
  mounted() {
    this.handleLoad();
  },
  methods: {
    handleLoad() {
      if (localStorage.getItem(`zudo.data`)) {
        const legacyData = localStorage.getItem(`zudo.data`);
        const listId = Date.now();
        localStorage.setItem(`zudo.data.${listId}`, legacyData);

        const currentLists = localStorage.getItem(`zudo.data.lists`);
        this.todoLists.push(...JSON.parse(currentLists || "[]"), {
          title: "Untitled List",
          id: listId,
        });
        localStorage.setItem("zudo.data.lists", JSON.stringify(this.todoLists));
        localStorage.removeItem("zudo.data");
      }
      const zudoDataLists = localStorage.getItem(`zudo.data.lists`);
      this.todoLists = JSON.parse(zudoDataLists || "[]");
    },
    handleSave() {
      localStorage.setItem(`zudo.data.lists`, JSON.stringify(this.todoLists));
    },
    handleEdit(newList) {
      this.newList = { title: newList.title, id: newList.id };
      const filteredTodos = this.todoLists.filter((ls) => ls != newList);
      this.todoLists = [...filteredTodos];
    },
    handleDelete(list) {
      this.todoLists = this.todoLists.filter((ls) => ls != list);
      this.handleSave();
    },
    handleAdd({ title, id }) {
      this.todoLists.push({
        title,
        id: id ?? Date.now(),
      });
      this.handleSave();
    },
    handleClearForm() {
      this.newList = { ...emptyTodoListObject };
    },
  },
  data() {
    return {
      todoLists: [],
      newList: { ...emptyTodoListObject },
    };
  },
};
</script>
