<template>
  <div v-if="items && items.length > 0" class="mb-8 pb-8">
    <v-sheet
      v-for="(item, i) in items"
      :key="i"
      width="80vw"
      class="mx-auto my-8 px-4 py-2"
      dark
      rounded
      outlined
      elevation="0"
      color="purple darken-3"
    >
      <v-row no-gutters>
        <v-col cols="12">
          <router-link
            class="white--text text-decoration-none"
            :to="`/todo/${item.id}`"
          >
            <v-col>
              <v-row no-gutters class="text-h5 font-weight-bold word-break">
                {{ item.title }}
              </v-row>
            </v-col>
          </router-link>
        </v-col>
        <v-col md="2" cols="8" offset="4" class="offset-md-0 actions-container">
          <v-row no-gutters>
            <v-col offset="5" cols="3">
              <v-btn @click="editItem(item)" color="purple lighten-2" small fab>
                <v-icon dark>{{ icons.mdiPencil }}</v-icon>
              </v-btn>
            </v-col>
            <v-col offset="1" cols="3">
              <v-btn @click="deleteItem(item)" color="red lighten-2" small fab>
                <v-icon dark>{{ icons.mdiTrashCan }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
  <div v-else>
    <EmptyTodo />
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { mdiPencil, mdiTrashCan } from "@mdi/js";
import EmptyTodo from "@/components/EmptyTodo.vue";
export default {
  name: "TodoLists",
  props: ["items"],
  emits: ["edit", "delete"],
  components: {
    EmptyTodo,
  },
  methods: {
    editItem(todoList) {
      this.$emit("edit", todoList);
    },
    deleteItem(todoList) {
      this.$emit("delete", todoList);
    },
  },
  data() {
    return {
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
    };
  },
};
</script>

<style scoped>
.word-break {
  word-break: break-word;
}
.actions-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}
</style>
